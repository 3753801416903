import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home/page.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/top-up',
        name: 'top-up',
        component: () => import('../views/top-up/page.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/result',
        name: 'result',
        component: () => import('../views/result/page.vue'),
        props: route => ({ status: route.query.status }),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('../views/sign-in/page.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/set-pin',
        name: 'set-pin',
        component: () => import('../views/set-pin/page.vue'),
        props: route => ({ setToken: route.query.token }),
        meta: {
            requiresAuth: false
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log(Store.getters.isAuthenticated)
        if (Store.getters.isAuthenticated) {
            next();
        }
        else {
            next({path : '/sign-in'})
        }
    } else {
        next() // make sure to always call next()! 
    }
})

export default router
