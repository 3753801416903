import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import router from '../router'
Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({ storage: window.localStorage })],
    state: {
        auth: {
            token: null,
            user: null
        }
    },
    getters: {
        isAuthenticated: (state) => {
            return state.auth.token !== null;
        },
        user: (state) => {
            return state.auth.user;
        },
        token: (state) => {
            return state.auth.token;
        }
    },
    mutations: {
        setAuth: (state, obj) => {
            state.auth.token = obj.token;
            state.auth.user = obj;
        },
        setBalance: (state, balance) => {
            state.auth.user.balance = balance;
        },
        logout: (state) => {
            state.auth.token = null;
            router.push('/sign-in')
        }
    },
    actions: {
        setAuth: ({ commit }, obj) => {
            commit('setAuth', obj);
        },
        setBalance: ({ commit }, balance) => {
            commit('setBalance', balance);
        },
        logout: ({ commit }) => {
            commit('logout')
        }
    },
    modules: {
    }
})
