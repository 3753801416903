import Vue from 'vue'
let monthArr = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'Augustus', 'September', 'October', 'November', 'December'];
Vue.filter('dayMonth', str => {
    let date = new Date(str);
    let month = monthArr[date.getMonth()].substring(0, 3);
    return `${date.getDate()} ${month}`
});
Vue.filter('dayMonthYear', str => {
    let date = new Date(str);
    let month = monthArr[date.getMonth()].substring(0, 3);
    return `${date.getDate()} ${month} ${date.getFullYear()}`
});
Vue.filter('monthDayYear', str => {
    let date = new Date(str);
    let month = monthArr[date.getMonth()].substring(0, 3);
    return `${month}, ${date.getDate()}, ${date.getFullYear()}`
});
Vue.filter('monthDayYearTime', str => {
    let date = new Date(str);

    //let ampm = date.getHours() > 12 ? "PM" : "AM";
    let time = ("" + date.getHours()).padStart(2, "0") + ":" + ("" + date.getMinutes()).padStart(2, "0");// + " " + ampm;

    let month = monthArr[date.getMonth()].substring(0, 3);
    return `${month}, ${date.getDate()}, ${date.getFullYear()} ${time}`
});

Vue.filter('time', str => {
    let date = new Date(str);
    //let ampm = date.getHours() > 12 ? "PM" : "AM";
    let time = ("" + date.getHours()).padStart(2, "0") + ":" + ("" + date.getMinutes()).padStart(2, "0");//+ " " + ampm;
    return `${time}`
});