import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@/assets/css/nucleo-svg.css'
import '@/assets/css/nucleo-icons.css'
import '@/assets/scss/material-dashboard.scss'
import '@/assets/css/style.css'
import * as signalR from '@microsoft/signalr'

Vue.prototype.$http = axios.create({
    validateStatus: () => true
});
Vue.prototype.$signalR = signalR;
Vue.config.productionTip = false

import * as LottiePlayer from "@lottiefiles/lottie-player";
Vue.use(LottiePlayer)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
import '@/helpers/filters/dateFilters.js'
import '@/helpers/filters/numberFilters.js'


window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());

gtag('config', 'G-VZBJ9CWX2X');
