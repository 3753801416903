<template>
    <div id="app">
        <div class="row align-items-center justify-content-end" v-if="isAuthenticated">
            <div class="col-auto">
                <button class="btn mb-0" v-on:click="logout">
                    <i class="material-icons me-sm-1" aria-hidden="true">logout</i>
                    <span>Sign Out</span>
                </button>
            </div>
        </div>
        <transition :name="animationName" mode="out-in">
            <router-view class="child-view" />
        </transition>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        data: function () {
            return {
                animationName: null
            }
        },
        methods: {
            ...mapActions(['logout']),
        },
        components: {
        },
        computed: {
            ...mapGetters(['isAuthenticated']),
        },
        watch: {
            $route: function (to, from) {
                const to_depth = to.path.split('/').length
                const from_depth = from.path.split('/').length
                this.animationName = to_depth < from_depth ? 'slide-right' : 'slide-left'
            }
        }
    }
</script>
